import apiClient from './basicapi'

const apiRoot = 'orderformforministries/admin/'

export function getExcelTable() {
  return apiClient.get(`${apiRoot}Orders/orders-as-excel`, {
    responseType: "blob",
    headers: {
      Accept:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    timeout: 120000,
  })
}